<template>
  <form-container class="form-container"
    :title="'Vincula tu cuenta para recibir pagos'"
    :description="'Al vincular tu cuenta le permitirás a tus clientes realizar compras en efectivo u online a través de Mercado Pago, Accede a tu cuenta de Mercado Pago y otorga permisos a la aplicación, si no tienes cuenta de Mercado Pago crea una nueva.'"
    :buttonText="'Vincular Cuenta'"
    :useLoading="false"
    @submit="linkAccount">
    <template v-slot:content class="form-content">
      <div class="form-title">
        Medios de pago aceptados
      </div>
      <div class="payment-methods-container">
        <div class="payment-methods-item" v-for="method in paymentMethods" :key="'payment_methods_'+method.name">
          <div class="payment-methods-image">
            <img :src="method.image">
          </div>
          <span class="payment-methods-title">{{ method.name }}</span>
        </div>
      </div>
    </template>
  </form-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import FormContainer from '@/components/Form/FormContainer'
import ticket from '@/assets/mp/ticket.svg'
import credit from '@/assets/mp/credit.svg'
import debit from '@/assets/mp/debit.svg'
import mp from '@/assets/mp/mp.svg'

export default {
  components: {
    FormContainer
  },
  data () {
    return {
      paymentMethods: [
        {
          image: ticket,
          name: 'Boleto'
        },
        {
          image: credit,
          name: 'Crédito'
        },
        {
          image: debit,
          name: 'Débito'
        },
        {
          image: mp,
          name: 'Mercado Pago'
        }
      ]
    }
  },
  computed: {
    ...mapState('shared', ['error']),
    ...mapState('paymentgateway', ['authorization'])
  },
  methods: {
    ...mapActions('paymentgateway', ['getAuthorization']),
    async linkAccount () {
      await this.getAuthorization()
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      window.location.href = this.authorization.url
    }
  }
}
</script>
<style scoped>
.form-container{
  height: 100%;
}
.form-content{
  height: 100%;
}
.form-title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-top: 2rem;
}
.payment-methods-container {
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 2.688rem 0;
  width: 100%;
}
.payment-methods-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0.75rem;
}
.payment-methods-image {
  display: flex;
  align-items: center;
  border: 0.094rem solid #e5e5e5;
  border-radius: 4.375rem;
  height: 4.375rem;
  justify-content: center;
  width: 4.375rem;
}
.payment-methods-title {
  font-size: 0.875rem;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 1.125rem;
  margin-top: 0.625rem;
}
</style>
